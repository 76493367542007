<template>
  <!-- <div class="datewrapopen" @click="dateinfo=!dateinfo">
    <span>{{$t('front.give.detailSearch')}}</span>
  </div> -->
  <div class="datewrap" v-if="dateinfo">
    <h4>{{$t('front.give.detailSearch')}}</h4>
    <ul>
      <li class="dateperiod">
        <input type="date" v-model="model.startDate" @change="onChangeStartDate"/>
        <span>~</span>
        <input type="date" v-model="model.endDate" @change="onChangeEndDate"/>
      </li>
      <li class="datebtns">
        <!--a :class="{'on': day === 0}" @click="getDate(0)">{{$t('front.common.today')}}</a-->
        <a :class="{'on': day === 7}" @click="getDate(7)">{{$t('front.common.aWeek')}}</a>
        <a :class="{'on': day === 15}" @click="getDate(15)">{{$t('front.common.15day')}}</a>
        <a :class="{'on': day === 30}" @click="getDate(30)">{{$t('front.common.1month')}}</a>
        <!--a v-if="add === '3month'" class="tmonth" @click="getDate(90)">3개월</a-->
      </li>
      <li class="before">
        <input :id="id" :name="id" type="checkbox" :checked="oldYn ==='Y'" @change="setOldYn"/>
        <label :for="id"></label>
        <span>이전데이터 검색</span>
      </li>
      <!-- <li>
        <a v-if="add !== '3month' || !retail" class="search" @click="onSearch()">{{$t('front.board.look')}}</a>
      </li> -->
      <!--li v-if="add === '3month' || retail">
        <input class="ml5" type="text" :placeholder="$t('front.common.memId')" v-if="retail" v-model="memId">
        <a class="search" @click="onSearch()">{{$t('front.give.search')}}</a>
      </li-->
    </ul>
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'

export default {
  name: 'DateFilterMobile',
  data () {
    return {
      dateinfo: true
    }
  },
  mixins: [DateFilter],
  emits: ['setOldYn', 'search', 'update'],
  props: [
    'add'
  ]
}
</script>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped>
#PTpage .tab-content input {height: 22px; min-width:123px; border: solid 1px #dadde2; border-radius: 5px; box-sizing: border-box; line-height: 22px; font-size: 16px; font-weight: bold; }
@media (max-width: 1000px) {
  #PTpage .tab-content input[type="date"] {height: 36px;}
}
</style>
