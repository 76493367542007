<template>
  <div class="datesearch">
    <slot />
    <input type="date" :value="model.startDate" @change="onChangeStartDate"/>
    <span>~</span>
    <input type="date" :value="model.endDate" @change="onChangeEndDate"/>
    <!--a class="bga" :class="{on: day == 0}" @click="getDate(0)">{{$t('front.common.today')}}</a-->
    <a class="bga" :class="{on: day == 7}" @click="getDate(7)">{{$t('front.common.aWeek')}}</a>
    <a class="bga" :class="{on: day == 15}" @click="getDate(15)">{{$t('front.common.15day')}}</a>
    <a class="bga" :class="{on: day == 30}" @click="getDate(30)">{{$t('front.common.1month')}}</a>
    <a class="bga" :class="{on: day == 90}" @click="getDate(90)" v-if="!retail">{{$t('front.common.3month')}}</a>
    <!--input class="ml5" type="text" :placeholder="$t('front.give.id')" v-if="retail" v-model="memId"-->
    <!-- <a class="bgf" @click="onSearch()">{{$t('front.give.search')}}</a> -->
    <div class="before" v-if="isOldYn">
       <input :id="id" :name="id" type="checkbox" :checked="oldYn ==='Y'" @change="setOldYn"/>
       <label :for="id"></label>
       <span>이전데이터 검색</span>
    </div>
  </div>
</template>

<script>
import { getDateStr, getSubDaysDate } from '@/libs/utils'

export default {
  name: 'DateFilter',
  props: {
    startDate: {
      type: String,
      default: getDateStr(getSubDaysDate(new Date(), 7))
    },
    endDate: {
      type: String,
      default: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
    },
    retail: {
      type: Boolean,
      default: false
    },
    defaultDay: {
      type: Number,
      default: 7
    },
    isOldYn: {
      type: Boolean,
      default: false
    },
    oldYn: {
      type: String,
      default: 'N'
    },
    id: {
      type: String,
      default: new Date().getTime()
    }
  },
  data () {
    return {
      model: {
        startDate: null,
        endDate: null
      },
      memId: '',
      day: 7
    }
  },
  created () {
    this.day = this.defaultDay
    this.model.startDate = this.startDate.split(' ')[0]
    this.model.endDate = this.endDate.split(' ')[0]
    if (this.$route.name.indexOf('bet') > -1) {
      this.day = 0
      this.model.startDate = getDateStr(getSubDaysDate(new Date(), this.day))
    }
  },
  methods: {
    setOldYn (value) {
      this.$emit('setOldYn', value.target.checked ? 'Y' : 'N')
    },
    getDate (value) {
      this.day = value
      this.model.startDate = getDateStr(getSubDaysDate(new Date(), value))
      if (value === 0) {
        this.model.endDate = getDateStr(getSubDaysDate(new Date(), value))
      }
      const model = {
        startDate: this.model.startDate + ' 00:00:00',
        endDate: this.model.endDate + ' 23:59:59'
      }
      if (this.retail) {
        model.memId = this.memId
      }
      this.$emit('update', model)
      // this.$emit('update', this.model)
      this.onSearch(value)
    },
    onSearch (value) {
      let result = false
      if (value || value === 0) {
        result = true
      }
      this.$emit('search', result)
    },
    onChangeStartDate (event) {
      this.model.startDate = event.target.value

      const model = {
        startDate: this.model.startDate + ' 00:00:00',
        endDate: this.model.endDate + ' 23:59:59'
      }
      if (this.retail) {
        model.memId = this.memId
      }
      this.$emit('update', model)
      this.onSearch()
    },
    onChangeEndDate (event) {
      this.model.endDate = event.target.value
      const model = {
        startDate: this.model.startDate + ' 00:00:00',
        endDate: this.model.endDate + ' 23:59:59'
      }
      if (this.retail) {
        model.memId = this.memId
      }
      this.$emit('update', model)
      this.onSearch()
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
